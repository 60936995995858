import React from 'react';
import { Link } from 'react-router-dom';

export const DataDisclaimer: React.FC = (): React.ReactElement => {
  return (
    <p className="mt-4 text-xs text-gray">
      Fundamentals financial data and Market data provided by EOD Historical Data.{' '}
      <Link to="/terms-and-conditions">Terms & Conditions</Link> apply
    </p>
  );
};
